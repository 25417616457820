<template>
  <div>
    <a-page-header title="同城城市管理" class="hearStyle" />
    <a-form layout="inline">
      <a-form-item label="城市名称：">
        <a-input v-model.trim="cityName" placeholder="请输入城市名称" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm"> 
          <a-icon type="redo" />
          重置 
        </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      style="margin-top: 10px"
      :pagination="false"
      :data-source="tableData"
      :rowKey="(record) => record.id"
      bordered
    >
      <span slot="project_name_list" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{ record.project_name_list }}
          </template>
          <span class="field">{{ record.project_name_list }}</span>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="edit(record)" size="small" v-if="$checkButtonPermissions($buttonPermissionsConfig.socialManage.sameCityManage.edit)"> 编辑 </a-button>
      </span>
    </a-table>
    <div class="pageSwitch">
      <a-pagination
        show-size-changer
        :page-size="pageSize"
        show-quick-jumper
        :default-current="page"
        v-if="paginationShow"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="pageOnChange"
      />
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewHandleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal
      width="600px"
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      ok-text="提交"
      cancel-text="取消"
      @cancel="handleCancel"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 15 }">
        <a-form-model-item label="上传图片" prop="fileList">
          <a-upload
            name="file"
            list-type="picture-card"
            :action="IMG_API + '/oss/files'"
            class="avatar-uploader"
            :file-list="form.fileList"
            @change="handleChange"
            @preview="handlePreview"
            :before-upload="beforeUpload"
          >
            <div v-if="this.form.fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <div class="upload_tip">请上传尺寸为690*330，PNG，JPG格式的图片</div>
        </a-form-model-item>
        <a-form-model-item label="排序" prop="city_sort">
          <a-input
            type="number"
            max="99999"
            placeholder="请填写排序序号"
            oninput="if(value.length>5)value=value.slice(0,5)"
            v-model="form.city_sort"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import { IMG_API } from "../../config/index";
import * as api from "@/api/userMessage";
export default {
  data() {
    // 校验排序值
    let checkValue = (rule, value, callback) => {
      if (value >= 1) {
        return callback();
      }
      // 不合法
      callback(new Error("排序最小值为1"));
    };
    return {
      paginationShow: true,
      deleteId: "",
      previewImage: "",
      previewVisible: false,
      IMG_API: IMG_API,
      editValue: {},
      total: 0,
      page: 1,
      pageSize: 10,
      title: "编辑",
      visible: false,
      confirmLoading: false,
      cityName: "",
      form: {
        city_sort: "",
        fileList: [],
      },
      rules: {
        city_sort: [
          { required: true, message: "请填写排序序号", trigger: "change" },
          { validator: checkValue, trigger: "change" },
        ],
        fileList: [{ required: true, message: "请上传文件", trigger: "change" }],
      },
      columns: [
        {
          title: "排序",
          dataIndex: "city_sort",
          key: "city_sort",
        },
        { title: "城市", dataIndex: "city_name", key: "city_name" },
        {
          title: "项目",
          dataIndex: "project_name_list",
          key: "project_name_list",
          width: 650,
          scopedSlots: { customRender: "project_name_list" },
        },
      ],
      tableData: [],
      // 列表操作按钮权限配置
      actions: [
        this.$buttonPermissionsConfig.socialManage.sameCityManage.edit
      ]
    };
  },
  mounted() {
    // 获取列表数据
    this.getTableList();
  },
  methods: {
    async getTableList() {
      let obj = {
        cityName: this.cityName,
        pageNum: this.page,
        pageSize: this.pageSize,
      };
      const res = await api.selectCityPage(obj);
      if (res.code === "200") {
        this.tableData = res.data.items;
        this.total = res.data.total;
        this.paginationShow = true;
      }
    },
    //搜索筛选数据
    search() {
      this.page = 1;
      this.getTableList();
    },
    onShowSizeChange(current, pageSize) {
      this.page = current;
      this.pageSize = pageSize;
      this.getTableList();
    },
    pageOnChange(current) {
      this.page = current;
      this.getTableList();
    },
    handleChange({ fileList }) {
      if (fileList.length != 0) {
        let type = fileList[0].name.substring(fileList[0].name.lastIndexOf(".") + 1);
        const isJPG = type === "png" || type === "jpg";
        if (isJPG) {
          this.form.fileList = fileList;
        }
      } else {
        this.form.fileList = [];
      }
    },
    previewHandleCancel() {
      this.previewVisible = false;
    },
    //预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    //上传文件之前校验图片大小
    beforeUpload(file) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJPG = type === "png" || type === "jpg";
      if (!isJPG) {
        this.$message.error("请上传正确的图片!");
        return false;
      }
      const isSize = new Promise(function (resolve, reject) {
        const width = 690; // 限制图片尺寸
        const height = 330;
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("请上传正确的图片!");
          return Promise.reject();
        }
      );
      return isJPG && isSize;
    },
    //重置
    resetSearchForm() {
      this.paginationShow = false;
      this.cityName = "";
      this.page = 1;
      this.pageSize = 10;
      this.getTableList();
    },
    // 点击编辑赋值
    async edit(record) {
      this.form = {
        city_sort: "",
        fileList: [],
      };
      const res = await api.selectById({ id: record.id });
      if (res.code === "200") {
        let imgList = [];
        if (res.data.city_image) {
          var obj = {
            uid: "1",
            name: "image.png",
            status: "done",
            url: res.data.city_image,
          };
          imgList.push(obj);
        }
        this.form = {
          fileList: imgList,
          city_sort: res.data.city_sort,
        };
      } else {
        this.$message.error(res.msg);
      }
      this.editValue = record;
      this.visible = true;
    },
    //编辑提交
    handleOk() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.confirmLoading = true;
          let obj = {
            id: this.editValue.id,
            city_image: this.form.fileList[0].response
              ? this.form.fileList[0].response.redirect_uri
              : this.form.fileList[0].url,
            city_sort: Number(this.form.city_sort),
          };
          const res = await api.updateCityById(obj);
          this.confirmLoading = false;
          if (res.code === "200") {
            this.$message.success("编辑成功!");
            this.getTableList();
            this.visible = false;
          } else {
            this.$message.success(res.msg);
          }
        } else {
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.confirmLoading = false;
      this.$refs.ruleForm.clearValidate();
    },
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.columns.push(this.$backTableAction());
  }
};
</script>
<style lang="scss" scoped>
.action a {
  color: #1890ff;
  margin-right: 15px;
  outline: none;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 630px;
}
.upload_tip {
  line-height: 20px;
  font-size: 12px;
}
.pageSwitch {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
.hearStyle {
  padding-left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 237, 240);
}
</style>